@import "./mixin";

.badge {
    @apply px-2 py-1 rounded-md inline-block font-bold leading-none;

    &.badge-rounded {
        @apply rounded-full;
    }

    @include bgtheme();
}
