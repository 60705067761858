.lds-ring {
    display: inline-block;
    position: relative;

    div {
        @apply border-t-primary border-l-transparent border-b-transparent border-r-transparent ;

        box-sizing: border-box;
        display: block;
        position: absolute;
        border-width: 8px;
        border-style: solid;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }

        &.light {
            @apply border-t-white border-l-transparent border-b-transparent border-r-transparent ;
        }
    }

}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}