@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

@import "./theme";

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

:root {
    font-family: "Nunito";
}