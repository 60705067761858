#main {
  @apply ml-0 duration-500 ease-in-out;

  .page {
    @apply p-8;
    .page-heading {
        @apply mb-4;
    }

    .page-content {
    }
  }
}

.title {
  @apply text-3xl font-bold text-secondary-dark
}
.subtitle {
  @apply text-opacity-50 text-secondary-dark
}