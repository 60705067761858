input,
select,
textarea {
    @apply relative bg-transparent p-3 focus-visible:outline-none border read-only:bg-gray-100 disabled:bg-gray-100 border-secondary-dark border-opacity-10 rounded-lg w-full duration-200 block;
    z-index: 1;
}

.input-group {
    @apply mb-4;

    .input-wrapper {
        @apply relative w-full;

        label {
            @apply text-base left-3 absolute top-1/2 -translate-y-1/2 text-secondary-dark text-opacity-75 duration-150 bg-white;
            user-select: none;
            z-index: 0;
        }

        input {
            &:focus-visible,
            &:not(:placeholder-shown) {
                &~label {
                    @apply top-0 text-sm px-1 text-opacity-100;
                    z-index: 1;
                }
            }
        }

        &.append {

            input,
            select,
            textarea {
                @apply rounded-r-none;
            }

            button {
                @apply rounded-l-none;
            }
        }
    }

    .input-message {
        @apply text-xs m-3;
    }

    &.invalid {
        .input-wrapper {
            @apply relative w-full;

            label {
                @apply text-danger;
            }

            input,
            select,
            textarea {
                @apply border-danger;
            }

        }

        .input-message {
            @apply text-danger;
        }
    }

}