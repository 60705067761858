#navbar {
  @apply w-full h-20 flex px-8 items-center;

  .sidebar-toggler {
    @apply flex justify-center items-center text-primary;

    svg {
      @apply cursor-pointer;
    }
  }

  .account-popover {
    .toggler {
      @apply flex space-x-3 items-center cursor-pointer;

      .text {
        @apply text-right;
        .title {
          @apply font-bold text-base;
        }
        .subtitle {
          @apply text-sm;
        }
      }
      .image {
        @apply aspect-square h-11 rounded-full overflow-hidden;
      }
    }
    .dropdown {
      @apply absolute top-3 bg-white right-0 rounded-lg max-w-md w-full overflow-hidden;

      .popover-item {
        @apply w-full text-secondary-dark flex [&:not(.popover-link)]:p-4 leading-none font-semibold duration-300 cursor-pointer relative overflow-hidden;

        &::before {
          @apply bg-gradient-to-r from-primary to-primary-light opacity-0 duration-300;

          content: "";
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          width: 100%;
          z-index: -100;
        }

        a {
          @apply flex p-4 w-full;

          & > svg {
            @apply mr-4 text-secondary-dark text-opacity-50;
          }
        }

        & > svg {
          @apply mr-4 text-secondary-dark text-opacity-50;
        }

        &:hover {
            @apply bg-primary bg-opacity-20 text-primary;
          }

        &.active {
          @apply text-white;
          &::before {
            @apply opacity-100;
          }
          & > svg {
            @apply text-white;
          }
          a {
            & > svg {
              @apply text-white;
            }
          }
        }

      }
    }
  }
}
