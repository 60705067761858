.carousel-wrapper {
    @apply relative;

    .carousel {
        @apply relative;
        .carousel-items {
            @apply flex overflow-x-scroll scroll-smooth snap-x snap-mandatory w-full;
    
            .carousel-item{
                @apply w-full flex-shrink-0;
            }
        }
        .carousel-nav {
            &> * {
                @apply absolute top-1/2 -translate-y-1/2 text-white opacity-60 p-6 cursor-pointer;
            }
            .next {
                @apply right-0;
            }
            .prev {
                @apply left-0;
            }
        }
        .carousel-indicators {
            @apply flex gap-2 justify-center absolute bottom-0 p-3 left-1/2 -translate-x-1/2;
    
            .indicator {
                @apply w-4 h-4 bg-gray-500 rounded-full opacity-60 cursor-pointer;
    
                &.active {
                    @apply bg-white;
                }
            }
        }
    
    }
}