.card {
    @apply bg-white rounded-xl p-6;
    .card-header {
        @apply mb-3;

        .title {
            @apply text-xl font-bold text-secondary-dark
        }
        .subtitle {
            @apply text-opacity-50 text-secondary-dark
        }
    }

    .card-body {
        
    }
}