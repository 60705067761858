*:has(> .messages) { 
    @apply relative bg-amber-900 bg-opacity-10;
    background-image: url("../../../public/img/bg-dark.png");
}
.messages{
    @apply relative p-6 h-[75vh] overflow-y-scroll flex flex-col space-y-1;

    .message {
        @apply relative bg-white rounded-xl mr-auto text-sm max-w-xs;

        .head {
            @apply m-3 overflow-hidden relative rounded-lg cursor-pointer;
        }

        .body {
            @apply m-3 relative;
        }

        .footer {
            @apply m-3;
        }

        &.me {
            @apply mr-0 ml-auto;
        }

        .time {
            @apply absolute bottom-0 right-0 text-xs hidden;
        }
    }
}

.chats {
    .chat {
        @apply flex hover:bg-slate-100 duration-200 cursor-pointer w-full p-4 space-x-3 items-center;

        .unread {
            @apply bg-primary text-white aspect-square w-12 text-center rounded-full flex items-center justify-center;
        }
    }
}