table {
  @apply w-full rounded-lg border-separate border-spacing-0;

  thead {
    tr {
      th {
        @apply border-t border-b;
        
        &:first-child {
          @apply rounded-tl-lg border-l;
        }
        &:last-child {
            @apply rounded-tr-lg border-r;
        }

        &:not(:last-child) {
          // @apply border-r;
        }
      }
    }
  }
  tbody {
    tr {
      &:last-child {
        td {
          @apply border-b;
          &:first-child {
            @apply rounded-bl-lg border-l;
          }
          &:last-child {
              @apply rounded-br-lg border-r;
          }
  
          &:not(:last-child) {
            // @apply border-r;
          }
        }
      }

      td:last-child{
        @apply border-r;
      }
      td:first-child{
        @apply border-l;
      }
    }
  }

  td, th {
    @apply px-6 py-4 text-left;
  }

  &.small {

    td, th {
      @apply px-4 py-2 text-left;
    }
  }
}
