@mixin background($property, $bg, $text) {
    &#{$property} {
        @apply duration-200 bg-opacity-100 border-opacity-100 bg-#{$bg} #{$text} border border-#{$bg};

        &:is(a):not(:disabled) , &:is(button):not(:disabled){
            &:hover{
                @apply bg-opacity-20 bg-#{$bg} text-#{$bg};
            }
        }

        &-light {
            @apply duration-200 bg-opacity-20 border-opacity-20 bg-#{$bg} text-#{$bg} border border-#{$bg};

            &:is(a):not(:disabled) , &:is(button):not(:disabled){
                &:hover{
                    @apply bg-#{$bg} #{$text};
                }
            }
        }

        &-outline {
            @apply duration-200 bg-transparent border border-#{$bg} text-#{$bg};

            &:is(a):not(:disabled) , &:is(button):not(:disabled){
                &:hover{
                    @apply bg-#{$bg} #{$text};
                }
            }
        }
    }
}

@mixin bgtheme(){
    @include background(".primary","primary", "text-white");
    @include background(".success","success", "text-white");
    @include background(".warning","warning", "text-white");
    @include background(".danger","danger", "text-white");
    @include background(".info","info", "text-white");
    @include background(".secondary","secondary", "text-white");
}