@import "./variables";

#sidebar {
  @apply fixed top-0 -left-80 h-screen w-80 bg-white shadow-xl shadow-slate-200 transition-[left] duration-500;

  &.active{
    @apply  left-0;

    &~#main {
      @apply ml-80
    }
  }

  .sidebar-wrapper {
    .sidebar-header {
      @apply flex justify-between px-6 pt-8 pb-4;
    }

    .sidebar-menu {
      @apply px-6 mt-6;

      .sidebar-title {
        @apply mt-4 mb-3 mx-4 font-semibold;
      }
      .sidebar-item {
        @apply w-full text-secondary-dark flex [&:not(.sidebar-link)]:p-4 mt-2 leading-none font-semibold rounded-lg duration-300 cursor-pointer relative overflow-hidden;

        &::before {
          @apply bg-gradient-to-r from-primary to-primary-light opacity-0 duration-300;

          content: "";
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          width: 100%;
          z-index: -100;
        }

        a {
          @apply flex p-4 w-full;

          & > svg {
            @apply mr-4 text-secondary-dark text-opacity-50;
          }
        }

        & > svg {
          @apply mr-4 text-secondary-dark text-opacity-50;
        }

        &:hover {
            @apply bg-primary bg-opacity-20 text-primary;
          }

        &.active {
          @apply text-white;
          &::before {
            @apply opacity-100;
          }
          & > svg {
            @apply text-white;
          }
          a {
            & > svg {
              @apply text-white;
            }
          }
        }

      }

      .sidebar-dropdown {
        .sidebar-dropdown-menu {
          @apply pl-6 h-auto overflow-hidden duration-300;

          &>div {
            @apply duration-500 ease-in-out;
          }

          &.open {
            @apply h-auto;
          }
          .sidebar-item {
            &.active {
              @apply text-primary font-bold;
              &::before {
                @apply opacity-0;
              }
              & > svg {
                @apply text-primary font-bold;
              }
              a {
                & > svg {
                  @apply text-primary font-bold;
                }
              }
            }
          }
        }
      }
    }
  }
}
