@import "./mixin";

.alert {
    @apply p-4 rounded-md mb-3;

    .title {
        @apply text-2xl font-bold;
    }
    @include bgtheme();
}
